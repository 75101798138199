import { Fragment, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../Redux/hooks/hooks';
import { GetAllCategory } from '../../../Redux/reducers/categoryReducer/action';
import { MainBannerOne } from '../../CustomComponent/Banners/MainBannerOne';
import { SimpleMainBanner } from '../../CustomComponent/Banners/SimpleMainBanner';
import { Carousel } from '../../CustomComponent/Carousels/Carousel';
import { CarouselFullscreen } from '../../CustomComponent/Carousels/CarouselFullscreen';
import { MainFooter } from '../../CustomComponent/Footers/MainFooter';
import { RecommendBlock } from '../../CustomComponent/RecommendBlock';
import { Section } from '../../CustomComponent/Section';

import style from './style.main.module.scss';
// const mbg1 = require('../../../Assets/Backgrounds/mini_banner1.png');
// const mbg2 = require('../../../Assets/Backgrounds/mini_banner2.png');
// const mbg3 = require('../../../Assets/Backgrounds/mini_banner3.png');

//const banner1 = require('../../../Assets/NewBgs/main/banners/1.png');
import banner1 from '../../../Assets/NewBgs/main/banners/1.jpg';
import banner2 from '../../../Assets/NewBgs/main/banners/2.jpg';
import banner3 from '../../../Assets/NewBgs/main/banners/3.jpg';
import banner4 from '../../../Assets/NewBgs/main/banners/4.jpg';
import banner5 from '../../../Assets/NewBgs/main/banners/5.jpg';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const bg1 = require('../../../Assets/NewBgs/main/below1.png');
const bg2 = require('../../../Assets/NewBgs/main/below2.png');
const bg3 = require('../../../Assets/NewBgs/main/below3.png');
const bg4 = require('../../../Assets/NewBgs/main/below4.png');
const bg5 = require('../../../Assets/NewBgs/main/below5.png');

const mbg1 = require('../../../Assets/Backgrounds/bg6.jpg');
const mbg2 = require('../../../Assets/Backgrounds/bg7.jpg');
const mbg3 = require('../../../Assets/Backgrounds/bg8.jpg');
// const banner2 = require('../../../Assets/NewBgs/main/banners/2.png');
// const banner3 = require('../../../Assets/NewBgs/main/banners/3.png');
// const banner4 = require('../../../Assets/NewBgs/main/banners/4.png');
// const banner5 = require('../../../Assets/NewBgs/main/banners/5.png');
const logo = require('../../../Assets/Logo.png');

export const MainView: React.FC = () => {
	const dispatch = useAppDispatch();
	const rf = useRef<HTMLDivElement | any>();
	const nav = useNavigate();
	const { t } = useTranslation();
	useEffect(() => {
		rf.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
	}, []);

	function selectCatalog(title: string) {
		nav(`/catalog/` + title);
	}

	return (
		<section className="flex flex-col bg-light">
			{/* <motion.div
				initial={{ opacity: 0, visibility: 'hidden' }}
				animate={{ opacity: 1, visibility: 'collapse' }}
				transition={{ delay: 2 }}
				className="overflow-hidden">
				<CarouselFullscreen
					selectedIndex={0}
					images={[banner1, banner2, banner3, banner4, banner5]}
				/>
			</motion.div> */}
			<div className="max-h-[95vh] relative">
				<CarouselFullscreen
					selectedIndex={0}
					images={[banner1, banner2, banner3, banner4, banner5]}
				/>
				<motion.div
					initial={{ opacity: 1 }}
					animate={{
						opacity: 0,
						visibility: 'hidden',
						transition: { opacity: { delay: 2.5 }, visibility: { delay: 2.7 } },
					}}
					className="absolute top-0 left-0 z-[200] bg-light w-full h-screen gap-[1rem] flex items-center justify-center flex-col overflow-hidden">
					<motion.img
						initial={{ scale: 0 }}
						animate={{
							scale: [0, 1.1, 1],
							transition: { delay: 0.2, ease: 'backOut', duration: 0.4 },
						}}
						alt="logo"
						src={logo}
						className={`w-[226px]`}
					/>
					<motion.h1
						initial={{ scale: 0 }}
						animate={{
							scale: [0, 1.05, 1],
							transition: { delay: 0.4, ease: 'backOut', duration: 0.4 },
						}}
						className="uppercase font-black text-xl">
						{t("factory of children's happiness!")}
					</motion.h1>
				</motion.div>
			</div>
			{/* <CarouselFullscreen
				selectedIndex={0}
				images={[
					'https://i.ibb.co/qM8QLHC/1.png',
					'https://i.ibb.co/T0jVC11/2.png',
					'https://i.ibb.co/R0Wrjhw/3.png',
					'https://i.ibb.co/9VmymxZ/4.png',
					'https://i.ibb.co/0XzphSj/5.png',
				]}
			/> */}
			<section
				className={`${style.slidercarouserContainer}`}
				id="main_carousel">
				<img alt="bg1" src={bg1} className={`${style.item}`} />
				<img alt="bg1" src={bg3} className={`${style.item}`} />
				<img alt="bg1" src={bg4} className={`${style.item}`} />
				<img alt="bg1" src={bg2} className={`${style.item}`} />
				<img alt="bg1" src={bg5} className={`${style.item}`} />
			</section>
			<section className={`${style.thirdblockImages}`}>
				<img alt="mbg1" src={mbg1} className={`${style.item}`} />
				<img alt="mbg2" src={mbg2} className={`${style.item}`} />
				<img alt="mbg3" src={mbg3} className={`${style.item}`} />
			</section>
			<RecommendBlock />
			{/* <Section /> */}
			{/* <MainBannerOne /> */}
			<SimpleMainBanner />
		</section>
	);
};
